.App {
  background-color: #000;
  max-width: 100vw;
  max-height: 100vh;
  width: 100vw;
  height: 100vh;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  & img {
    object-fit: cover;
  }
}

.watermark {
  position: absolute;
  z-index: 1000;
  opacity: 0.5;
  width: 200px;
}